/* Style for the footer */
.footer {
  
    color: #fff;
    text-align: center;
    position: absolute;
    width: 100%;
    display: flex;
    
     
  }

.left-side {
    background-color: maroon; 
    padding: 5px;
    flex: 1; 
    
  }

.right-side {
    background-color: white; 
    padding: 5px;
    margin-top: 10px;
    flex: 1;
    display: flex;
    flex-direction: column; 
  }
 
.content {
    margin-bottom: auto; 
  }
  
 
  .footer-contact-info {
   
    color:maroon;
    
  }
  
  
  .contact-item {
    
    color: maroon; 
   
    
  }
  .left-side p {
    font-size: 20px; 
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .footer {
    flex-direction: column; 
  }

  .left-side,
  .right-side {
    width: 100%; 
    margin: 0; 
  }

  .right-side {
    margin-top: 0; 
  }

  .left-side p {
    font-size: 16px; 
  }

  .contact-item {
    text-align: center; 
  }
}