.service-container {
    background-image: url('../Assets/Hero3.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: left;
    font-size: 24px;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 900px;
    position: relative; 

  }
  
   .service-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    max-width: 800px;
    justify-content: space-between; 
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin-top: 150px;
    
  }
  
  .service-content p {
    font-size: 16px; 
    display: inline-block; 
    margin: 5px  0; 
    font-weight: 200;
  }

  h3 {
    z-index: 1; 
  }

  @media only screen and (max-width: 600px) {
    .service-container {
      min-height: auto; 
    }
  
    .service-content {
      max-width: 100%;
      border-radius: 0;
      padding: 10px;
      margin-top: 20px; 
    }
  
    .service-content p {
      font-size: 14px; 
    }
   
  }
  