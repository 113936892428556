
.card-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .card {
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 10px;
    width: 250px;
    height: 350px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column; 
   
  }
  
  .card img {
    max-width: 100%;
    border-radius: 10px 10px 0 0;
    max-height: 60%; 
    object-fit: cover;
    
  }
  
  .slider-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .slider-controls button {
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .slider-controls button:hover {
    background-color: maroon;
  }
  
  .slider-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

  @media only screen and (max-width: 600px) {
    

    .service-container {
      display: flex;
      flex-direction: column; 
      text-align: center;
    }
    
    .card-slider {
      width: 100%; 
    }
    
    .service-card {
      width: 100%; 
      margin-bottom: 20px; 
    }
    
    .service-card img {
      max-width: 100%; 
    }
    
    .card-slider {
      overflow: hidden; 
    }
    
    .card-slider .slide {
      width: 100%; 
    }
    
    .service-card h3 {
      font-size: 18px; 
    }
    
    .service-card p {
      font-size: 14px; 
    }
    
    .service-container {
      padding: 20px; 
    }
    
    .card-slider {
      margin-top: 20px; 
    }
    
.card-list{
  flex-direction: column;
}
  }  