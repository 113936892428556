.bg-img {
    background-image: url("../Assets/Hero3.jpeg");
    min-height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .container {
    position: absolute;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%); 
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    width: 500px;
    padding: 16px;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.8);
    border: 3px solid maroon; 
    border-radius: 10px; 
  }
  
.left-section {
    padding: 16px;
    border-left: 3px solid #800000; 
    margin-left: 150px;
    order:1;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: left;
  }


  i {
    margin-right: 10px;
    font-size: 20px;
  }
  
  .right-section {
    
    padding: 16px;
  }
  
  label {
    display: block; 
    margin-bottom: 5px; 
  }
  
  input[type="text"],
  input[type="password"] {
    width: 300px;
    padding: 15px;
    margin: 5px 0;
    border: none;
    background: #f1f1f1;
  }
  
  textarea {
    width: 300px;
    padding: 15px;
    margin: 5px 0 15px 0; 
    border: none;
    background: #f1f1f1;
  }
  
  input[type="text"]:focus,
  input[type="password"]:focus,
  textarea:focus {
    background-color: #ddd;
    outline: none;
  }
  
  .btn {
    background-color: maroon;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 300px;
    opacity: 0.9;
  }
  
  .btn:hover {
    opacity: 1;
  }
  
.icon {
  display: flex;
  align-items: center;
  margin-bottom: 5px; 
}

.icon svg {
  font-size: 24px; 
  margin-right: 10px; 
}

.text p {
  margin-left: 10px;
  
}



/* Media query for mobile styles */
@media only screen and (max-width: 600px) {
  .bg-img {
    min-height: 400px;
  }

  .container {
    width: 90%; 
    grid-template-columns: 1fr; 
    gap: 10px;
    margin: 1px;
    max-width: 400px; 
    padding: 10px; 
    background-color: rgba(255, 255, 255, 0.8);
    border: 3px solid maroon;
    border-radius: 10px;
  }

  .left-section {
    border-left: none; 
    margin-left: 0;
    padding: 10px;
    order: 0;
  }

  .right-section {
    padding: 10px;
  }


  input[type="text"],
  input[type="password"],
  textarea {
    width: 100%; 
    box-sizing: border-box; 
    margin: 5px 0;
  }


  .btn {
    width: 100%;
  }

  
  .contact-info {
    margin-top: 10px;
  }

  
  .info {
    margin-bottom: 5px;
  }
  
  
  .icon svg {
    font-size: 20px;
    margin-right: 5px;
  }

  
  .text p {
    margin-left: 5px;
  }
}
