.hero-container {
    background-image: url('../Assets/Hero1.png');
    background-size: cover;
    background-position: center;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: left;
    font-size: 24px;
    justify-content: flex-start;
  }
   
  .hero-container .content {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 15px;
    max-width: 600px;
  margin: 0 auto;
  font-size: 16px; 
  line-height: 1.4; 
  padding: 20px 40px;
  margin-top: 50px;
  
    
    
  }

  .hero-container .content p {
    text-align: left;
     
  }

  @media only screen and (max-width: 600px) {
    .hero-container {
      height: auto;
    }
  
    .hero-container .content {
      max-width: 100%;
      border-radius: 0; 
      padding: 10px; 
      
    }
  
    .hero-container .content p {
      font-size: 14px; 
    }
  }