.App {
  text-align: center;
}

/* Default styles for all screens */

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}
