.about-container {
    background-image: url('../Assets/Hero3.jpeg');
    background-size: cover;
    background-position: center;
    height: 130vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: left;
    font-size: 24px;
    justify-content: flex-start;

  }
  
   .about-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 15px;
    max-width: 600px;
    margin: 0 auto;
    font-size: 16px; 
    line-height: 1.4; 
    padding: 20px 40px;
    
    
  }
  
  .about-container .about-content p {
    text-align: left; 
     
  
  }

  /* Mobile styles */
@media only screen and (max-width: 600px) {
  .about-container {
    height: auto;
  }

  .about-content {
    max-width: 100%;
    border-radius: 0;
    margin-top: 20px; 
  }
  
  .about-container .about-content p {
    font-size: 14px; 
  }
}