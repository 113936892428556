
.topnav {
  position: fixed; 
  top: 0; 
  width: 100%; 
  background-color: white; 
  z-index: 100; 
  
}
  
  .topnav a {
    float: right;
    color: black;
    text-align: center;
    padding: 30px 16px;
    text-decoration: none;
    font-size: 17px;
    margin-top: 30px;
  }
  
  
  .topnav a:hover {
    background-color: maroon;
    color: white;
    border-radius: 10px;
  }
  
  
  .topnav a.active {
    background-color: #04AA6D;
    color: white;
  }

.logo {
    float: left; 
    padding: 10px;
  }
  
 
  .logo img {
    max-width: 100px; 
    height: auto; 
  }

  /* Media query for mobile styles */
@media only screen and (max-width: 600px) {
  .topnav {
    padding-top: 60px; 
  }

  .topnav a {
    float: none; 
    display: block; 
    text-align: center;
    padding: 15px 16px; 
    margin-top: 0;
    margin: 10px; 
  }
  .logo {
    float: none; 
    text-align: center;
    margin-bottom: 10px; 
  }
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive a {
    display: inline-block;
    float: none;
    text-align: left;
  }

  .topnav a.icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
  .topnav a.icon:hover {
    background-color: transparent; 
    color: black; 
  }

}