/* Style for the logo band */
.logo-band {
    background-color: white; 
    display: flex;
    align-items: center;
    padding: 5px 0;
    height: 50px;
    position:absolute;
    left:0;
    width: 100%;
    z-index:2;
    justify-content: space-between;
    height: 50px;
    bottom:70px;
  }
  

  .logo-band img {
    margin: 0 10px; 
    max-height: 40px;
    
  }

  /* Animation for moving the logos horizontally */
@keyframes moveLogos {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  
  .logo-band img {
    animation: moveLogos 10s linear infinite; /* Adjust the duration and timing function as needed */
  }
  