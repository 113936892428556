
  .card-container {
    padding: 20px; 
    text-align: center; 
    background-color: maroon;
    height: 120px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
  
   
  .card h4 {
    margin: 10px 0; 
    color: white;
    font-size: 1.5rem;

  
  }
  
  .card-list {
    display: flex; 
    justify-content: space-between; 
  }
  